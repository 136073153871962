<template>
  <div class="position-relative">

    <b-modal
      id="modalDelete"
      ref="modalDelete"
      :title="`Are you sure you want to delete this LAN Network: ${recordToDelete.name}?`"
      centered
      ok-title="Delete"
      @ok="deleteNetwork"
    >
      <div>
        Permanently deleted records cannot be recovered later.
      </div>
    </b-modal>
    <div class="section-block m-2">
      <b-spinner
        v-if="loading"
        variant="primary"
        label="Spinning"
        class="position-absolute"
        style="top:20%; left:48%;"
      />
      <b-card
        v-else
        no-body
      >
        <div class="site-hardware-actions site-hardware-actions_management">

          <div class="section-block__title mb-0">
            <h2>LAN Network Settings</h2>
          </div>
          <div class="double-button">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="navigateToAddNetwork"
            >
              <feather-icon
                icon="PlusIcon"
                width="16"
                height="16"
                class="mr-25"
              />
              Add Network
            </b-button>
            <b-dropdown
              toggle-class="more-dropdown-transparent"
              dropdown
              right
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle"
                  color="#F0554E"
                />
              </template>
              <b-dropdown-item
                @click="pushToSite"
              >
                <feather-icon
                  icon="UploadCloudIcon"
                  size="20"
                  class="mr-50"
                />
                <span>Push to</span>
              </b-dropdown-item>
              <b-dropdown-item
                @click="pullFromSite"
              >
                <feather-icon
                  icon="DownloadCloudIcon"
                  size="20"
                  class="mr-50"
                />
                <span>Pull from</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
        <table-mobile-solo
          :handle-search="null"
          :items="items"
          :custom-class="'no-padding mt-3'"
          :loading="loading"
          :fields="fields"
          :pagination="{
            pagination: pagination,
            perPage: perPage,
            currentPage: page,
            pageSizeOptions: pageSizeOptions,
            enabled: false,
            perPageChanged: perPageChanged,
            changePage: changePage,
          }"
          :rules="{
            tagged_ports(val) {
              return val.length > 0 ? val.map(port => `${port.interface}-${port.usage}`).join(' ,') : ''
            },
            untagged_ports(val) {
              return val.length > 0 ? val.map(port => `${port.interface}-${port.usage}`).join(' ,') : ''
            },
            comment(val) {
              return val || '-'
            },
          }"
          :actions="[{
            label: 'Edit',
            type: 'primary',
            icon: 'EditIcon',
            visible: () => true,
            click: (item) => navigateToEdit(item.id),
          }, {
            label: 'Remove',
            type: 'danger',
            icon: 'TrashIcon',
            visible: (item) => !item.is_default,
            click: (item) => handleDelete(item.id, item.name),
          }]"
        />
        <validation-observer ref="lanSettingsUpdate">
          <b-form class="lanSettingsUpdate">
            <vue-good-table
              ref="VueGoodTable"
              :is-loading="loading"
              :columns="fields"
              :rows="items"
              mode="remote"
              class="hide-mobile-table"
              disable-sort
              compact-mode
              :sort-options="{
                enabled: true,
                initialSortBy: {field: orderBy, type: sortedBy}
              }"
              :pagination-options="{
                enabled: true,
                perPage: pagination.per_page,
                setCurrentPage: onChangePage,
              }"
              @on-sort-change="onSortChange"
            >

              <template
                slot="table-row"
                slot-scope="props"
              >
                <span v-if="props.column.field === 'action'">
                  <span>
                    <b-dropdown
                      variant="link"
                      toggle-class="text-decoration-none"
                      no-caret
                    >
                      <template v-slot:button-content>
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="16"
                          class="text-body align-middle mr-25"
                        />
                      </template>
                      <b-dropdown-item
                        v-b-modal.modal-edit
                        @click.prevent="navigateToEdit(props.row.id)"
                      >
                        <feather-icon
                          icon="Edit2Icon"
                          class="mr-50"
                        />
                        <span>Edit</span>
                      </b-dropdown-item>
                      <b-dropdown-item
                        :disabled="props.row.is_default"
                        @click.prevent="handleDelete(props.row.id, props.row.name)"
                      >
                        <feather-icon
                          icon="TrashIcon"
                          class="mr-50"
                        />
                        <span>Delete</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </span>
                </span>
                <span v-if="props.column.field === 'is_active'">
                  <b-form-checkbox
                    v-model="props.row.is_active"
                    name="is_enable"
                    switch
                    inline
                    :disabled="props.row.is_default"
                    aria-label="fff"
                    @change="updateNetworkStatus(props.row.id, props.row.is_active)"
                  />
                </span>
                <span
                  v-if="props.column.field === 'name'"
                >
                  <b-link
                    @click="navigateToEdit(props.row.id)"
                  >
                    {{ props.row.name }}
                  </b-link>
                </span>
                <span
                  v-if="props.column.field === 'tagged_ports'"
                  key="tagged_ports"
                >
                  <span>{{ props.row.tagged_ports ? props.row.tagged_ports.map(port => `${port.interface}-${port.usage}`).join(' ,') : '-' }}</span>
                </span>
                <span
                  v-if="props.column.field === 'untagged_ports'"
                  key="untagged_ports"
                >
                  {{ props.row.untagged_ports ? props.row.untagged_ports.map(port => `${port.interface}-${port.usage}`).join(', ') : '-' }}
                </span>
                <span
                  v-if="props.column.field === 'comment'"
                >
                  {{ props.row.comment }}
                </span>
                <span
                  v-if="props.column.field === 'vlan_id'"
                >
                  {{ props.row.vlan_id }}
                </span>

                <span
                  v-if="props.column.field === 'interface_ip'"
                >
                  {{ props.row.interface_ip }}
                </span>

              </template>
              <!-- pagination -->
              <template
                slot="pagination-bottom"
              >
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1" />
                  <div>
                    <b-pagination
                      v-if="pagination.last_page !== 1"
                      :value="pagination.current_page"
                      :total-rows="pagination.total"
                      :per-page="perPage"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @change="onChangePage"
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </b-form>
        </validation-observer>
      </b-card>
    </div>
    <b-modal
      id="modalSoftDelete"
      ref="modalSoftDelete"
      :title="deleteErrorPopUpInfo.title"
      centered
      ok-only
      ok-variant="secondary"
      ok-title="Cancel"
    >
      <p v-html="deleteErrorPopUpInfo.content" />
    </b-modal>
    <b-modal
      id="modalDeleteLoader"
      ref="modalDeleteLoader"
      :title="`${modalTitle}...`"
      hide-footer
      centered
      hide-close
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      size="sm"
    >
      <div class="d-flex justify-content-center align-items-center mt-2 mb-2">

        <b-spinner
          variant="primary"
          label="Spinning"
          style="width: 3rem; height: 3rem;"
          huge
        />
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BForm,
  BSpinner,
  BButton,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
  BLink,
  BPagination,

} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import {
  required, max,
} from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import { VueGoodTable } from 'vue-good-table'
import TableMobileSolo from '@/components/TableMobileSolo.vue'
import axios from '../../axios-resolver'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    ValidationObserver,
    BPagination,
    BDropdown,
    BDropdownItem,
    BForm,
    BLink,
    BSpinner,
    BButton,
    VueGoodTable,
    BFormCheckbox,
    TableMobileSolo,
  },
  props: {
    hardwareId: {
      required: true,
      type: Number,
    },
    siteId: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {
      modalTitle: '',

      required,
      max,
      loading: true,
      isButtonDisabled: false,
      checkupForm: {},
      server: null,
      items: [],
      itemToEdit: {},
      recordToDelete: {
        id: null,
        name: '',
      },

      deleteErrorPopUpInfo: {
        content: '',
        title: '',
      },
      pagination: {
        total: 0,
        per_page: 10,
        last_page: 0,
        current_page: 1,
      },
      orderBy: null,
      sortedBy: null,
      searchValue: '',
      mobileSearchFields: {
        name: '',
        vlan_id: '',
        comment: '',
      },

      fields: [
        {
          label: 'Name',
          field: 'name',
          sortable: true,
        },
        {
          label: 'Interface Address',
          field: 'interface_ip',
          sortable: true,
        },
        {
          label: 'Vlan ID',
          field: 'vlan_id',
          sortable: true,
        },
        {
          label: 'Tagged Ports',
          field: 'tagged_ports',
          sortable: false,
        },
        {
          label: 'Untagged Ports',
          field: 'untagged_ports',
          sortable: false,
        },
        {
          label: 'Comment',
          field: 'comment',
          sortable: false,
        },
        {
          label: 'Enabled',
          field: 'is_active',
          sortable: true,
        },
        {
          label: 'Actions',
          field: 'action',
          sortable: false,
        },
      ],
    }
  },
  watch: {
    recordToDelete(val) {
      if (val.id) {
        this.$refs.modalDelete.show()
      } else {
        this.$refs.modalDelete.hide()
      }
    },
  },
  async created() {
    this.fetchTable()
  },
  methods: {
    async pullFromSite() {
      try {
        this.modalTitle = 'Pulling'
        this.$refs.modalDeleteLoader.show()
        await axios.put(`/site/${this.siteId}/network/setting/lan/pull`)
        this.fetchTable(true)
      } catch (error) {
        console.warn(error)
      } finally {
        this.$refs.modalDeleteLoader.hide()
      }
    },
    async handleMobileSearch(search, searchValue) {
      if (this.search !== searchValue) {
        this.searchValue = searchValue
      }
    },
    async pushToSite() {
      try {
        this.modalTitle = 'Pushing'
        this.$refs.modalDeleteLoader.show()
        await axios.put(`/site/${this.siteId}/network/setting/lan/push`)

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Data pushed to the controller',
            icon: 'BellIcon',
            variant: 'success',
          },
        })
      } catch (error) {
        console.warn(error)
      } finally {
        this.$refs.modalDeleteLoader.hide()
      }
    },
    async fetchTable(isShowPulledMessage = false) {
      try {
        const sortOptionURL = this.orderBy && this.sortedBy ? `&orderBy=${this.orderBy}&sortedBy=${this.sortedBy}` : ''
        const { data } = await axios.get(`/site/${this.siteId}/network/setting/lan/index?page=${this.pagination.current_page}${sortOptionURL}`)
        this.items = data.data
        this.pagination = data.meta.pagination
        this.loading = false

        if (isShowPulledMessage) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Data pulled from the controller',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
        }
      } catch (error) {
        console.warn(error)
      }
    },
    onSortChange(params) {
      this.orderBy = params[0].field
      this.sortedBy = params[0].type
      if (params[0].type) {
        this.fetchTable()
      }
    },
    onChangePage(page) {
      this.pagination.current_page = page
      this.fetchTable()
    },
    updateNetworkStatus(id, isEnable) {
      const switchTo = isEnable ? 'enable' : 'disable'
      try {
        axios.put(`/site/${this.siteId}/network/setting/lan/${id}/${switchTo}`)

        this.$toast({
          component: ToastificationContent,
          props: {
            title: `LAN is ${isEnable ? 'enabled' : 'disabled'} successfully`,
            icon: 'BellIcon',
            variant: 'success',
          },
        })
      } catch (error) {
        console.warn(error)
      }
    },
    navigateToAddNetwork() {
      this.$router.push({ name: 'site-summary-add-network' })
    },
    navigateToEdit(networkId) {
      this.$router.push({ name: 'site-summary-update-network', params: { id: this.siteId, networkId } })
    },
    handleDelete(id, name) {
      this.recordToDelete = {
        id,
        name,
      }
    },
    showModal(dataToDelete) {
      this.deleteErrorPopUpInfo = dataToDelete
      this.$refs.modalSoftDelete.show()
    },
    async deleteNetwork() {
      try {
        await axios.delete(`/site/${this.siteId}/network/setting/lan/${this.recordToDelete.id}`)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Network deleted',
            icon: 'TrashIcon',
            variant: 'success',
          },
        })
        this.recordToDelete = {
          id: null,
          name: '',
        }

        this.fetchTable()
      } catch (error) {
        const { status } = error.response

        if (status === 422) {
          let detailContent = ''

          if (error?.response?.data?.fields) {
            detailContent += 'You cannot remove this item because the entity has the following relation(s):<br>\n'
            Object.keys(error.response.data.fields).forEach(field => {
              detailContent += ` - ${field}: \n 
        <ul class="mb-1">
          ${error.response.data.fields[field].map(item => `<li>${item}</li>`).join('')}
          </ul>`
            })
          }

          this.showModal({
            title: 'Validation error',
            content: detailContent,
            icon: 'BellIcon',
            variant: 'danger',
          })
        }
      }
    },
  },
}
</script>

<style lang="scss">
</style>
