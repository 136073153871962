var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"table-border-container",attrs:{"no-body":""}},[_c('b-row',{staticClass:"mt-2 mb-2"},[_c('b-col',[_c('div',{staticClass:"site-hardware-actions site-hardware-actions_management wifi-setting"},[_c('div',{staticClass:"wifi-setting-label"},[_c('p',{staticClass:"label"},[_vm._v(" Registrations ")])]),_c('div',{staticClass:"double-button actions-wifi-settings"},[_c('b-dropdown',{ref:"dropdown",attrs:{"toggle-class":"more-dropdown-transparent","dropdown":"","right":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"text-body align-middle",attrs:{"icon":"FilterIcon","size":"16","color":"#F0554E"}})]},proxy:true}])},_vm._l((_vm.fieldLabelsSelected),function(label,field){return _c('b-dropdown-item',{key:field,on:{"click":function($event){return _vm.changeColumns(field)}}},[_c('b-form-checkbox',{on:{"change":function($event){return _vm.changeColumns(field)}},model:{value:(_vm.fieldLabelsSelected[field].selected),callback:function ($$v) {_vm.$set(_vm.fieldLabelsSelected[field], "selected", $$v)},expression:"fieldLabelsSelected[field].selected"}},[_vm._v(" "+_vm._s(label.label)+" ")])],1)}),1)],1)])])],1),_c('b-tabs',{staticClass:"site-info-tabs no-margins",class:{'one-tab': _vm.newCapsman},attrs:{"card":"","pills":""},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[(!_vm.newCapsman)?_c('b-tab',{attrs:{"title":"Wi-Fi 5","active":""}}):_vm._e(),_c('b-tab',{attrs:{"title":"Wi-Fi 6+"}})],1),_c('table-mobile-solo',{attrs:{"handle-search":null,"loading":_vm.isLoading,"items":_vm.items,"custom-class":'no-padding',"fields":_vm.fields.filter(function (field) { return field.field !== 'is_enabled'; }),"key-value":"accessPoint","actions":[{
      label: 'Delete',
      type: 'primary',
      icon: 'TrashIcon',
      visible: function () { return true; },
      click: function (item) { return _vm.handleDelete(item.id); },
    }]}}),_c('b-card-body',[_c('vue-good-table',{ref:"VueGoodTable",staticClass:"hide-mobile-table",attrs:{"is-loading":_vm.isLoading,"columns":_vm.fields,"rows":_vm.items,"mode":"remote","compact-mode":"","sort-options":{
        enabled: true,
        multipleColumns: true,
        initialSortBy: {field: _vm.orderBy, type: _vm.sortedBy}
      }},on:{"on-sort-change":_vm.onSortChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'action')?_c('span',[_c('span',[_c('b-dropdown',{attrs:{"variant":"link","toggle-class":"text-decoration-none","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"text-body align-middle mr-25",attrs:{"icon":"MoreVerticalIcon","size":"16"}})]},proxy:true}],null,true)},[_c('b-dropdown-item',{on:{"click":function($event){$event.preventDefault();return _vm.handleDelete(props.row.id)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"TrashIcon"}}),_c('span',[_vm._v("Delete")])],1)],1)],1)]):(props.column.field === 'mac_address')?_c('div',{directives:[{name:"b-modal",rawName:"v-b-modal.access-control-modal",modifiers:{"access-control-modal":true}}],on:{"click":function($event){return _vm.openBlockDeviceModal(props.formattedRow[props.column.field])}}},[_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('template',{slot:"loadingContent"},[_c('b-spinner',{attrs:{"variant":"primary","label":"Spinning"}})],1)],2)],1),_c('AddToBlockListModal',{attrs:{"site-id":_vm.siteId,"selected-item":_vm.selectedItem,"is-edit-form":false},on:{"close-modal":_vm.resetModal}}),_c('b-modal',{ref:"modalDelete",attrs:{"id":"modalDelete","title":"Are you sure you want to permanently delete this record?","centered":"","ok-title":"Delete"},on:{"ok":_vm.deleteModalHandler}},[_c('div',[_vm._v(" Permanently deleted records cannot be recovered later. ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }