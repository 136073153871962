<template>
  <b-card
    no-body
    class="no-padding"
  >
    <b-modal
      id="modalDelete"
      ref="modalDelete"
      title="Are you sure you want to permanently delete this record?"
      centered
      ok-title="Delete"
      @ok="deleteRow"
    >
      <div>
        Permanently deleted records cannot be recovered later.
      </div>
    </b-modal>
    <div class="section-block m-2">
      <div class="site-hardware-actions site-hardware-actions_management mb-2">

        <div class="section-block__title mb-0">
          <h2>NAT Rules - Port Forwarding</h2>
        </div>
        <div class="double-button">
          <b-button
            variant="primary"
            @click="handleAddNat"
          >
            <feather-icon
              icon="PlusIcon"
              size="18"
              class="mr-25"
            />
            Add NAT
          </b-button>
          <b-dropdown
            toggle-class="more-dropdown-transparent"
            dropdown
            right
          >
            <template v-slot:button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="text-body align-middle"
                color="#F0554E"
              />
            </template>
            <b-dropdown-item
              @click="pushToSite"
            >
              <feather-icon
                icon="UploadCloudIcon"
                size="20"
                class="mr-50"
              />
              <span>Push to</span>
            </b-dropdown-item>
            <b-dropdown-item
              @click="pullToSite"
            >
              <feather-icon
                icon="DownloadCloudIcon"
                size="20"
                class="mr-50"
              />
              <span>Pull from</span>
            </b-dropdown-item>
            <b-dropdown-item
              @click.prevent="() => updateStats(false)"
            >
              <feather-icon
                icon="RefreshCcwIcon"
                class="mr-50"
              />
              <span>Refresh Stats</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
      <table-mobile-solo
        :handle-search="null"
        :items="items"
        :custom-class="'no-padding'"
        :loading="isLoading"
        key-value="comment"
        :fields="fields.filter(field => field.field !== 'is_enabled' && field.field !== 'order')"
        :rules="{
          protocol(value) {
            return value.name ? value.name : 'All'
          },
          chain(value) {
            return value.name ? value.name : '-'
          },
        }"
        :actions="[{
          label: 'Edit',
          type: 'primary',
          icon: 'EditIcon',
          visible: () => true,
          click: (item) => handleEditItem(item.id),
        }, {
          label: (item) => item.is_enabled ? 'Disable' : 'Enable',
          type: 'primary',
          icon: 'EditIcon',
          visible: () => true,
          click: (item) => updateNATStatus(item.id, item.is_enabled),
        }, {
          label: 'Remove',
          type: 'danger',
          icon: 'TrashIcon',
          visible: () => true,
          click: (item) => deleteHandler(item.id),
        }]"
      />
      <b-card-body>
        <vue-good-table
          ref="VueGoodTable"
          :is-loading="isLoading"
          :columns="fields"
          :rows="items"
          mode="remote"
          compact-mode
          class="vgt-unset-overflow hide-mobile-table"
          :sort-options="{
            enabled: true,
          }"
        >
          <template slot="loadingContent">
            <b-spinner
              variant="primary"
              label="Spinning"
            />
          </template>
          <template
            slot="table-row"
            slot-scope="props"
          >
            <span v-if="props.column.field === 'is_enabled'">
              <b-form-checkbox
                v-model="props.row.is_enabled"
                name="is_enable"
                switch
                inline
                aria-label="fff"
                @change="updateNATStatus(props.row.id, props.row.is_enabled)"
              />
            </span>
            <span v-else-if="props.column.field === 'action'">
              <span>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item
                    v-b-modal.modal-edit
                    @click.prevent="navigateToEdit(props.row.id)"
                  >
                    <feather-icon
                      icon="Edit2Icon"
                      class="mr-50"
                    />
                    <span>Edit</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="!props.row.is_default"
                    @click.prevent="deleteHandler(props.row.id)"
                  >
                    <feather-icon
                      icon="TrashIcon"
                      class="mr-50"
                    />
                    <span>Delete</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </span>
            <span v-else-if="props.column.field === 'order'">
              <span v-if="props.row.position > 1">
                <b-dropdown
                  :ref="`dropdown-${props.row.position}`"
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                  class="order-dropdown"
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="ArrowUpIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <p>Reorder NAT Rule</p>
                  <b-dropdown-divider />
                  <b-form-group
                    label="From:"
                    label-class="label-class"
                  >
                    <v-select
                      v-model="props.row.position"
                      :clearable="false"
                      label="From"
                      disabled
                    />
                  </b-form-group>
                  <b-form-group
                    label="To:"
                    label-class="label-class"
                  >
                    <v-select
                      v-model="changeOrder"
                      :clearable="false"
                      label="To"
                      :options="props.row.moveTo"
                      transition=""
                    />
                  </b-form-group>
                  <b-dropdown-divider />
                  <div class="dropdown-buttons">
                    <b-button
                      variant="primary"
                      class="cancel-btn"
                      @click="closeDropdown(props.row.position)"
                    >
                      Cancel
                    </b-button>
                    <b-button
                      variant="primary"
                      class="save-btn"
                      @click="saveOrder(props.row.id, changeOrder, props.row.position)"
                    >
                      Save
                    </b-button>
                  </div>
                </b-dropdown>
              </span>
            </span>
            <span v-else-if="props.column.field === 'protocol.name'">
              {{ props.formattedRow[props.column.field] || 'All' }}
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </b-card-body>
    </div>
    <b-modal
      id="modalDeleteLoader"
      ref="modalDeleteLoader"
      :title="`${modalTitle}...`"
      hide-footer
      centered
      hide-close
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      size="sm"
    >
      <div class="d-flex justify-content-center align-items-center mt-2 mb-2">

        <b-spinner
          variant="primary"
          label="Spinning"
          style="width: 3rem; height: 3rem;"
          huge
        />
      </div>
    </b-modal>
  </b-card>

</template>

<script>

import {
  BCard,
  BCardBody,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BButton,
  BSpinner,
  BFormCheckbox,
  BFormGroup,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required, max } from '@validations'
import Ripple from 'vue-ripple-directive'

import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'
import TableMobileSolo from '@/components/TableMobileSolo.vue'
import axios from '../../axios-resolver'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCardBody,
    VueGoodTable,
    BFormCheckbox,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BCard,
    BButton,
    BSpinner,
    vSelect,
    BFormGroup,
    TableMobileSolo,
  },
  props: {
    hardwareId: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {
      items: [],
      isLoading: true,

      changeOrder: null,
      modalTitle: '',

      recordToDelete: null,
      fields: [
        {
          field: 'order',
          label: '',
          sortable: false,
        },
        {
          field: 'position',
          label: 'Order',
          sortable: false,
        },
        {
          field: 'protocol.name',
          label: 'Protocol',
          mobileSpecial: 'protocol',
          sortable: false,
        },
        {
          field: 'src_address',
          label: 'To Address',
          sortable: false,
        },
        {
          field: 'src_port',
          label: 'To Port',
          sortable: false,
        },
        {
          field: 'dst_address',
          label: 'Dst Address',
          sortable: false,
        },
        {
          field: 'dst_port',
          label: 'Dst Port',
          sortable: false,
        },
        {
          field: 'bytes',
          label: 'Bytes',
          sortable: false,
        },
        {
          field: 'packets',
          label: 'Packets',
          sortable: false,
        },
        {
          field: 'chain.name',
          label: 'Chain',
          mobileSpecial: 'chain',
          sortable: false,
        },
        {
          field: 'comment',
          label: 'Comment',
          sortable: false,
        },
        {
          field: 'is_enabled',
          label: 'Enabled',
          sortable: false,
        },
        {
          field: 'action',
          label: 'Actions',
          sortable: false,
          thClass: 'action-th-width',
        },
      ],
      required,
      max,

      defaultFrom: 'test',
    }
  },
  watch: {
    recordToDelete(val) {
      if (val) {
        this.$refs.modalDelete.show()
      } else {
        this.$refs.modalDelete.hide()
      }
    },
  },
  created() {
    this.getNATs()
  },
  methods: {
    navigateToEdit(id) {
      this.$router.push({
        name: 'site-summary-edit-firewall-nat',
        params: {
          id: +this.$route.params.id,
          natId: id,
        },
      })
    },
    handleAddNat() {
      this.$router.push({
        name: 'site-summary-add-firewall-nat',
        params: {
          id: +this.$route.params.id,
        },
      })
    },
    closeDropdown(dropdownId) {
      this.$refs[`dropdown-${dropdownId}`].hide(true)
    },
    async saveOrder(id, position, dropdownId) {
      try {
        if (!position) {
          return
        }
        this.$refs[`dropdown-${dropdownId}`].hide(true)
        await axios.put(`/site/${this.$route.params.id}/firewall/nat/${id}/sort/${position - 1}`)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Order changed',
            icon: 'BellIcon',
            variant: 'success',
          },
        })

        this.getNATs()
      } catch (error) {
        console.warn(error)
      }
    },
    async getNATs(isUpdated = true) {
      const { data } = await axios.get(`site/${this.$route.params.id}/firewall/nat/index`)
      const sortedItems = data.data.sort((el1, el2) => el1.order - el2.order)
      const listLength = sortedItems.map((el, index) => index + 1)
      this.items = sortedItems.map(el => ({
        ...el,
        position: el.order + 1,
        moveTo: listLength.filter(pos => pos < (el.order + 1)),
        bytes: this.numberWithCommas(el.bytes),
        packets: this.numberWithCommas(el.packets),
        is_enabled: !el.is_disabled,
      }))

      if (!isUpdated) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Data updated',
            icon: 'BellIcon',
            variant: 'success',
          },
        })
      }

      this.isLoading = false
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    async pushToSite() {
      try {
        this.modalTitle = 'Pushing'
        this.$refs.modalDeleteLoader.show()
        await axios.put(`/site/${this.$route.params.id}/firewall/nat/push`)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Data pushed to the controller',
            icon: 'BellIcon',
            variant: 'success',
          },
        })
      } catch (error) {
        console.warn(error)
      } finally {
        this.$refs.modalDeleteLoader.hide()
        this.isLoading = false
      }
    },
    async pullToSite() {
      try {
        this.modalTitle = 'Pulling'
        this.$refs.modalDeleteLoader.show()
        await axios.put(`/site/${this.$route.params.id}/firewall/nat/pull`)
        await this.getNATs(false)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Data pulled from the controller',
            icon: 'BellIcon',
            variant: 'success',
          },
        })
      } catch (error) {
        const { status } = error.response

        if (!status) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Server error',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        } else {
          const { message } = error.response.data

          this.$toast({
            component: ToastificationContent,
            props: {
              title: message,
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        }
      } finally {
        this.$refs.modalDeleteLoader.hide()
        this.isLoading = false
      }
    },
    async updateNATStatus(dhcpId, isEnable) {
      const switchTo = isEnable ? 'enable' : 'disable'
      try {
        await axios.put(`/site/${this.$route.params.id}/firewall/nat/${switchTo}`, {
          ids: [`${dhcpId}`],
        })

        this.$toast({
          component: ToastificationContent,
          props: {
            title: isEnable ? 'NAT is enabled' : 'NAT is disabled',
            icon: 'BellIcon',
            variant: 'success',
          },
        })
      } catch (error) {
        const { status } = error.response

        if (!status) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Server error',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        }
      }
    },
    async updateStats() {
      try {
        this.isLoading = true
        const { data } = await axios.get(`site/${this.$route.params.id}/firewall/nat/refresh-stats`)
        const dataStats = data.data

        if (dataStats && dataStats.length) {
          this.items = this.items.map(el => ({
            ...el,
            bytes: this.numberWithCommas(dataStats.filter(elStats => elStats.hw_id === el.hw_id)[0]?.bytes ?? el.bytes),
            packets: this.numberWithCommas(dataStats.filter(elStats => elStats.hw_id === el.hw_id)[0]?.packets ?? el.packets),
          }))
        }
      } catch (error) {
        const { status } = error.response

        if (!status) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Server error',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        } else {
          const { data } = error.response
          this.$toast({
            component: ToastificationContent,
            props: {
              title: data.message,
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        }
      } finally {
        this.isLoading = false
      }
    },
    async deleteHandler(id) {
      this.recordToDelete = id
    },
    async deleteRow() {
      try {
        await axios.delete(`/site/${this.$route.params.id}/firewall/nat/${this.recordToDelete}`)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Deleted!',
            icon: 'BellIcon',
            variant: 'success',
          },
        })
        this.getNATs()
      } catch (error) {
        const { status } = error.response

        if (!status) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Server error',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        }
      }
    },
  },
}
</script>

<style lang="scss">
.vgt-responsive{
  padding: 2em 0;
  overflow-x: auto !important;
}
</style>
