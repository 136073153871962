<template>
  <b-card-code
    no-body
  >
    <b-modal
      id="licence-info"
      ref="licence-info"
      :title="selectedHardwareModalInfo.name ? 'Licence - ' + selectedHardwareModalInfo.name : 'Licence'"
      size="md"
      ok-title="Edit"
      cancel-title="Close"
      centered
      @ok="toEdit(selectedHardwareModalInfo.id)"
    >
      <div
        v-if="selectedHardwareModalInfo.category"
        class="view-modal"
      >
        <div class="mb-1 align-items-center view-modal__section-title">
          <feather-icon
            icon="ListIcon"
            width="18"
            height="18"
          />
          <h4>
            Licence details
          </h4>
        </div>
        <p v-if="selectedHardwareModalInfo.category">
          Category: <span>{{ selectedHardwareModalInfo.category.name }}</span>
        </p>
        <p v-if="selectedHardwareModalInfo.level">
          Level: <span>{{ selectedHardwareModalInfo.level.name }}</span>
        </p>
        <p v-if="selectedHardwareModalInfo.type">
          Type: <span>{{ selectedHardwareModalInfo.type.name }}</span>
        </p>
        <p v-if="selectedHardwareModalInfo.site">
          Site: <span>{{ selectedHardwareModalInfo.site.name }}</span>
        </p>
        <div
          v-if="selectedHardwareModalInfo.client || selectedHardwareModalInfo.reseller || selectedHardwareModalInfo.distributor || selectedHardwareModalInfo.masterDistributor"
          class="view-modal__owners"
        >
          <div class="mb-1 align-items-center view-modal__section-title">
            <feather-icon
              icon="UsersIcon"
              width="18"
              height="18"
            />
            <h4>Owner details</h4>
          </div>
          <p v-if="selectedHardwareModalInfo.client">
            Client: <span>{{ selectedHardwareModalInfo.client.name }}</span>
          </p>
          <p v-if="selectedHardwareModalInfo.reseller">
            Reseller: <span>{{ selectedHardwareModalInfo.reseller.name }}</span>
          </p>
          <p v-if="selectedHardwareModalInfo.distributor">
            Distributor: <span>{{ selectedHardwareModalInfo.distributor.name }}</span>
          </p>
          <p v-if="selectedHardwareModalInfo.masterDistributor">
            Master Distributor: <span>{{ selectedHardwareModalInfo.masterDistributor.name }}</span>
          </p>
        </div>
        <div class="mb-1 align-items-center view-modal__section-title">
          <feather-icon
            icon="InfoIcon"
            width="18"
            height="18"
          />
          <h4>Additional details</h4>
        </div>
        <div>
          <b-badge
            pill
            class="license-badge mb-1"
            :variant="selectedHardwareModalInfo.statusColor"
          >
            {{ selectedHardwareModalInfo.status.name }}
          </b-badge>
          <div class="licence-modal-switch">
            <p>Enabled/Disabled: </p>
            <b-form-checkbox
              v-model="selectedHardwareModalInfo.is_enable"
              name="is_enable"
              switch
              inline
              disabled
            />
          </div>
          <p v-if="selectedHardwareModalInfo.created_at">
            Creation Date: <span>{{ new Date(selectedHardwareModalInfo.created_at).toDateString() }}</span>
          </p>
          <p v-if="selectedHardwareModalInfo.activation_at">
            Activation Date: <span>{{ new Date(selectedHardwareModalInfo.activation_at).toDateString() }}</span>
          </p>
          <p v-if="selectedHardwareModalInfo.renewal_at">
            Renewal Date: <span>{{ new Date(selectedHardwareModalInfo.renewal_at).toDateString() }}</span>
          </p>
          <p v-if="selectedHardwareModalInfo.expiry_at">
            Expiry Date: <span>{{ new Date(selectedHardwareModalInfo.expiry_at).toDateString() }}</span>
          </p>
        </div>
      </div>
    </b-modal>
    <b-modal
      id="unallocated-modal"
      ref="unallocated-modal"
      title="Remove this License?"
      size="md"
      ok-title="Remove"
      cancel-title="Close"
      centered
      @ok="unlocateHardware"
      @cancel="idToUnlocate = null"
    >
      <div>
        <p>
          Are you sure you want to remove this License?
        </p>
      </div>
    </b-modal>
    <b-modal
      id="add-licence-modal"
      ref="add-licence-modal"
      title="Add Licence to the Site"
      size="xl"
      ok-only
      ok-title="Close"
      centered
      @ok="closeAddLicenceModal"
    >
      <vue-good-table
        ref="VueGoodTable"
        :is-loading="isLoadingAddLicence"
        compact-mode
        :columns="fields"
        :rows="addLicenceItems"
        :total-rows="paginationAdd.total"
        class="licence-table"
        :row-style-class="rowStyleClassFn"
        mode="remote"
        :sort-options="{
          enabled: true,
          multipleColumns: true,
          initialSortBy: {field: orderByAdd, type: sortedByAdd}
        }"
        :pagination-options="{
          enabled: true,
          perPage: listParamsAdd.perPage,
          setCurrentPage: listParamsAdd.page
        }"
        @on-sort-change="onSortListChangeAdd"
        @on-column-filter="onColumnFilterAdd"
      >
        <template slot="loadingContent">
          <b-spinner
            variant="primary"
            label="Spinning"
          />
        </template>
        <template
          slot="table-row"
          slot-scope="props"
        >
          <span
            v-if="props.column.field === 'action'"
          >

            <b-button-request
              class="add-licence-modal-button"
              icon="PlusIcon"
              :disabled="disabledSaveButton"
              text="Add Licence"
              :request="() => addLicenceToTheSite(props.row.id)"
              class-container="d-flex justify-content-end"
            />
          </span>
          <span
            v-else-if="props.column.field === 'number'"
            @click.prevent="onSelectHardwareInfo(props.row.id)"
          >
            <a href="">
              {{ props.formattedRow[props.column.field] }}
            </a>
          </span>
          <span
            v-else-if="props.column.field === 'masterDistributor.name'"
          >
            <router-link
              v-if="props.formattedRow['masterDistributor'] && props.formattedRow['masterDistributor'].is_link"
              :to="`/company-management/summary/${props.formattedRow['masterDistributor'].id}`"
            >
              {{ props.formattedRow[props.column.field] }}
            </router-link>
            <span v-else>
              <span v-if="props.formattedRow['masterDistributor']">
                {{ props.formattedRow[props.column.field] }}
              </span>
            </span>
          </span>
          <span
            v-else-if="props.column.field === 'distributor.name'"
          >
            <router-link
              v-if="props.formattedRow['distributor'] && props.formattedRow['distributor'].is_link"
              :to="`/company-management/summary/${props.formattedRow['distributor'].id}`"
            >
              {{ props.formattedRow[props.column.field] }}
            </router-link>
            <span v-else>
              <span v-if="props.formattedRow['distributor']">
                {{ props.formattedRow[props.column.field] }}
              </span>
            </span>
          </span>
          <span
            v-else-if="props.column.field === 'reseller.name'"
          >
            <router-link
              v-if="props.formattedRow['reseller'] && props.formattedRow['reseller'].is_link"
              :to="`/company-management/summary/${props.formattedRow['reseller'].id}`"
            >
              {{ props.formattedRow[props.column.field] }}
            </router-link>
            <span v-else>
              <span v-if="props.formattedRow['reseller']">
                {{ props.formattedRow[props.column.field] }}
              </span>
            </span>
          </span>
          <span
            v-else-if="props.column.field === 'client.name'"
          >
            <router-link
              v-if="props.formattedRow['client'] && props.formattedRow['client'].is_link"
              :to="`/company-management/summary/${props.formattedRow['client'].id}`"
            >
              {{ props.formattedRow[props.column.field] }}
            </router-link>
            <span v-else>
              <span v-if="props.formattedRow['client']">
                {{ props.formattedRow[props.column.field] }}
              </span>
            </span>
          </span>
          <span
            v-else-if="props.column.field === 'site.name'"
          >
            <router-link
              v-if="props.formattedRow['site']"
              :to="`/site/${props.formattedRow['site'].id}/hardware/index`"
            >
              {{ props.formattedRow[props.column.field] }}
            </router-link>
          </span>
          <span
            v-else-if="props.column.field === 'type' || props.column.field === 'category' || props.column.field === 'level'"
          >
            <span v-if="props.row[props.column.field]">
              {{ props.row[props.column.field].name }}
            </span>
          </span>
          <span v-else-if="props.column.field === 'status'">
            <b-badge
              pill
              :variant="setStatusColor(props.formattedRow[props.column.field].id)"
            >
              <span class="text-white">
                {{ props.formattedRow[props.column.field].name }}
              </span>
            </b-badge>
          </span>
          <span v-else-if="props.column.field === 'is_enable'">
            <b-form-checkbox
              v-model="props.formattedRow[props.column.field]"
              name="is_enable"
              :disabled="enableActionInProgress"
              switch
              inline
              @change="changeStatus(props.formattedRow)"
            />
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">
                Showing 1 to
              </span>
              <b-form-select
                v-model="perPage"
                :options="pageSizeOptions"
                class="mx-1"
                @change="perPageChanged"
              />
              <span class="text-nowrap"> of {{ paginationAdd.total }} entries </span>
            </div>
            <div>
              <b-pagination
                v-if="paginationAdd.last_page !== 1"
                :value="page"
                :total-rows="paginationAdd.total"
                :per-page="perPage"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @change="changePage"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-modal>
    <b-row
      class="mb-2"
    >
      <b-col>
        <div class="site-hardware-actions site-hardware-actions_management">
          <p>
            Site Licences
          </p>
          <b-button
            variant="primary"
            class="add-licence-modal-button"
            @click="$refs['add-licence-modal'].show()"
          >
            <feather-icon
              icon="PlusIcon"
              size="18"
              class="mr-25"
            />
            Add Licence
          </b-button>
        </div>
      </b-col>
    </b-row>

    <table-mobile-solo
      :search="searchValue"
      :handle-search="handleMobileSearch"
      :mobile-search-fields="mobileSearchFields"
      :loading="isLoading"
      :items="licenceItems"
      key-value="number"
      :custom-class="'no-padding'"
      :fields="fields"
      :pagination="{
        pagination: pagination,
        perPage: perPage,
        currentPage: page,
        pageSizeOptions: pageSizeOptions,
        enabled: false,
        perPageChanged: perPageChanged,
        changePage: changePage,
      }"
      :rules="{
        category(val) {
          return val.name
        },
        type(val) {
          return val.name
        },
        level(val) {
          return val.name
        },
      }"
      :actions="[{
        label: 'View',
        type: 'primary',
        icon: 'EyeIcon',
        visible: () => true,
        click: (item) => onSelectHardwareInfo(item.id),
      }, {
        label: 'Remove',
        type: 'danger',
        icon: 'TrashIcon',
        visible: () => true,
        click: (item) => idToUnlocate = item.id,
      }]"
    />
    <b-card-body>

      <!-- table -->
      <vue-good-table
        ref="VueGoodTable"
        :is-loading="isLoading"
        compact-mode
        :columns="fields"
        :rows="licenceItems"
        :total-rows="pagination.total"
        class="licence-table hide-mobile-table"
        :row-style-class="rowStyleClassFn"
        mode="remote"
        :sort-options="{
          enabled: true,
          multipleColumns: true,
          initialSortBy: {field: orderBy, type: sortedBy}
        }"
        :pagination-options="{
          enabled: true,
          perPage: listParams.perPage,
          setCurrentPage: listParams.page
        }"
        @on-sort-change="onSortListChange"
        @on-column-filter="onColumnFilter"
      >
        <template slot="loadingContent">
          <b-spinner
            variant="primary"
            label="Spinning"
          />
        </template>
        <template
          slot="table-row"
          slot-scope="props"
        >
          <span v-if="props.column.field === 'action'">
            <button
              v-if="!props.row.is_deleted"
              class="management-licence-delete"
              @click.prevent="idToUnlocate = props.row.id"
            >
              <feather-icon
                icon="TrashIcon"
                class="mr-50"
                size="20"
                color="#ABABBA"
              />
            </button>
          </span>
          <span
            v-else-if="props.column.field === 'number'"
            @click.prevent="onSelectHardwareInfo(props.row.id)"
          >
            <a href="">
              {{ props.formattedRow[props.column.field] }}
            </a>
          </span>
          <span
            v-else-if="props.column.field === 'masterDistributor.name'"
          >
            <router-link
              v-if="props.formattedRow['masterDistributor'] && props.formattedRow['masterDistributor'].is_link"
              :to="`/company-management/summary/${props.formattedRow['masterDistributor'].id}`"
            >
              {{ props.formattedRow[props.column.field] }}
            </router-link>
            <span v-else>
              <span v-if="props.formattedRow['masterDistributor']">
                {{ props.formattedRow[props.column.field] }}
              </span>
            </span>
          </span>
          <span
            v-else-if="props.column.field === 'distributor.name'"
          >
            <router-link
              v-if="props.formattedRow['distributor'] && props.formattedRow['distributor'].is_link"
              :to="`/company-management/summary/${props.formattedRow['distributor'].id}`"
            >
              {{ props.formattedRow[props.column.field] }}
            </router-link>
            <span v-else>
              <span v-if="props.formattedRow['distributor']">
                {{ props.formattedRow[props.column.field] }}
              </span>
            </span>
          </span>
          <span
            v-else-if="props.column.field === 'reseller.name'"
          >
            <router-link
              v-if="props.formattedRow['reseller'] && props.formattedRow['reseller'].is_link"
              :to="`/company-management/summary/${props.formattedRow['reseller'].id}`"
            >
              {{ props.formattedRow[props.column.field] }}
            </router-link>
            <span v-else>
              <span v-if="props.formattedRow['reseller']">
                {{ props.formattedRow[props.column.field] }}
              </span>
            </span>
          </span>
          <span
            v-else-if="props.column.field === 'client.name'"
          >
            <router-link
              v-if="props.formattedRow['client'] && props.formattedRow['client'].is_link"
              :to="`/company-management/summary/${props.formattedRow['client'].id}`"
            >
              {{ props.formattedRow[props.column.field] }}
            </router-link>
            <span v-else>
              <span v-if="props.formattedRow['client']">
                {{ props.formattedRow[props.column.field] }}
              </span>
            </span>
          </span>
          <span
            v-else-if="props.column.field === 'site.name'"
          >
            <router-link
              v-if="props.formattedRow['site']"
              :to="`/site/${props.formattedRow['site'].id}/hardware/index`"
            >
              {{ props.formattedRow[props.column.field] }}
            </router-link>
          </span>
          <span
            v-else-if="props.column.field === 'type' || props.column.field === 'category' || props.column.field === 'level'"
          >
            <span v-if="props.row[props.column.field]">
              {{ props.row[props.column.field].name }}
            </span>
          </span>
          <span v-else-if="props.column.field === 'status'">
            <b-badge
              pill
              :variant="setStatusColor(props.formattedRow[props.column.field].id)"
            >
              <span class="text-white">
                {{ props.formattedRow[props.column.field].name }}
              </span>
            </b-badge>
          </span>
          <span v-else-if="props.column.field === 'is_enable'">
            <b-form-checkbox
              v-model="props.formattedRow[props.column.field]"
              name="is_enable"
              :disabled="enableActionInProgress"
              switch
              inline
              @change="changeStatus(props.formattedRow)"
            />
          </span>
          <span v-else-if="props.column.field === 'remaining_units'">
            <span
              v-if="props.row.remaining_units_label"
              v-b-tooltip.hover
              :title="`${props.row.remaining_units_label}`"
            >
              {{ props.formattedRow[props.column.field] }}
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">
                Showing 1 to
              </span>
              <b-form-select
                v-model="perPage"
                :options="pageSizeOptions"
                class="mx-1"
                @change="perPageChanged"
              />
              <span class="text-nowrap"> of {{ pagination.total }} entries </span>
            </div>
            <div>
              <b-pagination
                v-if="pagination.last_page !== 1"
                :value="page"
                :total-rows="pagination.total"
                :per-page="perPage"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @change="changePage"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card-body>
    <b-modal
      id="modalSoftDelete"
      ref="modalSoftDelete"
      :title="deleteErrorPopUpInfo.title"
      centered
      ok-only
      ok-variant="secondary"
      ok-title="Cancel"
    >
      <p v-html="deleteErrorPopUpInfo.content" />
    </b-modal>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BCardBody,
  BPagination,
  BButton,
  BRow,
  BCol,
  BFormSelect,
  BModal,
  BSpinner,
  BBadge,
  BFormCheckbox,
  VBTooltip,
} from 'bootstrap-vue'
import { mapActions, mapState, mapMutations } from 'vuex'
import IndexPagesMixin from '@/mixins/IndexPages.vue'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import flatPickr from 'flatpickr'

import 'flatpickr/dist/flatpickr.css'
import 'flatpickr/dist/themes/material_blue.css'
import { ref, watch } from '@vue/composition-api'
import TableMobileSolo from '@/components/TableMobileSolo.vue'
import axios from '../../axios-resolver'
import BButtonRequest from '../../../../components/BButtonRequest.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BButtonRequest,
    BCardCode,
    BCardBody,
    BButton,
    BPagination,
    BRow,
    BCol,
    BModal,
    BFormSelect,
    BBadge,
    BFormCheckbox,
    BSpinner,
    VueGoodTable,
    TableMobileSolo,
  },
  mixins: [
    IndexPagesMixin,
  ],
  props: {
    fetchControllerLicence: {
      required: true,
      type: Function,
    },
  },
  data() {
    return {
      isLoading: true,
      uppdateRouteName: 'licence-update',
      enableActionInProgress: false,
      currentRoute: this.$route.path,

      idToUnlocate: null,
      deleteErrorPopUpInfo: {},
      selectedHardwareModalInfo: {},
      licenceItems: [],

      addLicenceItems: [],
      isLoadingAddLicence: true,

      pagination: {},
      paginationAdd: {},
      categories: [],
      types: [],
      levels: [],
      statuses: [],

      listParams: {
        page: 1,
        perPage: 25,
        searchJoin: 'and',
      },
      listParamsAdd: {
        page: 1,
        perPage: 25,
        searchJoin: 'and',
      },
      orderBy: null,
      sortedBy: null,
      orderByAdd: null,
      sortedByAdd: null,
      search: '',
      searchAdd: '',
      searchFields: '',
      searchFieldsAdd: '',

      companyLicence: [],
      searchValue: '',
      mobileSearchFields: {
        number: '',
      },

      fields: [
        {
          field: 'number',
          label: 'Licence',
          filterOptions: {
            enabled: true,
            placeholder: 'Filter',
            filterValue: '',
          },
        },
        {
          field: 'category',
          label: 'Product',
          filterOptions: {
            enabled: true,
            placeholder: 'Filter',
            filterValue: '',
          },
        },
        {
          field: 'type',
          label: 'Type',
          filterOptions: {
            enabled: true,
            placeholder: 'Filter',
            filterValue: '',
          },
        },
        {
          field: 'level',
          label: 'Level',
          filterOptions: {
            enabled: true,
            placeholder: 'Filter',
            filterValue: '',
          },
        },
        {
          field: 'created_at',
          label: 'Creation',
          type: 'date',
          dateInputFormat: 'yyyy-mm-dd',
          dateOutputFormat: 'yyyy-mm-dd',
          filterOptions: {
            enabled: true,
            placeholder: 'Creation',
            filterValue: '',
            filterFn: this.dateRangeFilter,
          },
        },
        {
          field: 'activation_at',
          label: 'Activation',
          type: 'date',
          dateInputFormat: 'yyyy-mm-dd',
          dateOutputFormat: 'yyyy-mm-dd',
          filterOptions: {
            enabled: true,
            placeholder: 'Activation',
            filterValue: '',
            filterFn: this.dateRangeFilter,
          },
        },
        {
          field: 'renewal_at',
          label: 'Renewal',
          type: 'date',
          dateInputFormat: 'yyyy-mm-dd',
          dateOutputFormat: 'yyyy-mm-dd',
          filterOptions: {
            enabled: true,
            placeholder: 'Renewal',
            filterValue: '',
            filterFn: this.dateRangeFilter,
          },
        },
        {
          field: 'remaining_units',
          label: 'Units Remaining',
          filterOptions: {
            enabled: true,
            placeholder: 'Filter',
            filterValue: '',
          },
        },
        {
          field: 'status',
          label: 'Status',
          filterOptions: {
            enabled: true,
            placeholder: 'Filter',
            filterValue: '',
          },
        },
        {
          field: 'action',
          label: 'Action',
          sortable: false,
          thClass: 'action-th-width',
          hidden: !this.$can('update', 'licences') && !this.$can('delete', 'licences'),
        },
      ],
    }
  },
  setup() {
    const companyName = ref('')
    const companyId = ref('')

    watch(companyName, companyId, () => {
      if (this.$route.query.companyId) {
        this.companyId = this.$route.query.companyId
        const company = this.getCompany(this.$route.query.companyId)
        this.companyName = company.name
      } else {
        this.companyName = ''
      }
    })
    return {
      companyName: '',
    }
  },
  computed: {
    ...mapState('licence', {
      permissions: 'permissions',
    }),
    itemsForExport() {
      const arr = JSON.parse(JSON.stringify(this.items))
      arr.map(item => {
        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const property in item) {
          // eslint-disable-next-line no-param-reassign
          if (typeof item[property] === 'object') {
            if (item[property] && item[property].name) {
              // eslint-disable-next-line no-param-reassign
              item[property] = item[property].name
            }
          }
        }
        return item
      })
      return arr
    },
    page() {
      return this.$route.query?.params || {}
    },
  },
  watch: {
    async $route(to) {
      const additionalPathname = to.fullPath.replace(to.path, '')
      if (!additionalPathname) {
        await this.clearAllData()
        this.getData()
        this.companyName = ''
      }
    },
    idToUnlocate() {
      if (this.idToUnlocate) {
        this.$refs['unallocated-modal'].show()
      } else {
        this.$refs['unallocated-modal'].hide()
      }
    },
  },
  async mounted() {
    await this.getSiteLicence()
    this.updateFilters()
    this.getAvailableSiteLicence()
    const inputs = [
      'input[placeholder="Renewal"]',
      'input[placeholder="Activation"]',
      'input[placeholder="Creation"]',
      'input[placeholder="Expiry"]',
    ]
    inputs.forEach(input => {
      flatPickr(input, {
        dateFormat: 'Y-m-d',
        mode: 'range',
        rangeSeparator: ',',
        onChange(selectedDates, datestr) {
          this.notFullyDate = true
          const arrOfDates = datestr.split('to')
          if (arrOfDates.length > 1) {
            document.querySelector(input).value = `${`${arrOfDates[0].replace(/\s/g, '')},${arrOfDates[1].replace(/\s/g, '')}`}`
            this.notFullyDate = false
          }
        },
        allowInput: false,
      })
    })
    if (this.$route.query.companyId) {
      const company = await this.getCompany(this.$route.query.companyId)
      this.companyName = company.name
    }
  },
  methods: {
    ...mapActions('licence', {
      fetchItems: 'getItems',
      updateLicenceStatus: 'updateLicenceStatus',
      getCompany: 'getCompany',
      deleteRecord: 'deleteItem',
      recoverRecord: 'recoverItem',
    }),
    updateFilters() {
      this.fields = this.fields.map(item => {
        if (item.label === 'Product') {
        // eslint-disable-next-line no-param-reassign
          item = { ...item, filterOptions: { ...item.filterOptions, filterDropdownItems: [...this.categories.map(el => ({ value: el.id, text: el.name }))] } }
        }
        if (item.label === 'Type') {
          // eslint-disable-next-line no-param-reassign
          item = { ...item, filterOptions: { ...item.filterOptions, filterDropdownItems: [...this.types.map(el => ({ value: el.id, text: el.name }))] } }
        }
        if (item.label === 'Level') {
          // eslint-disable-next-line no-param-reassign
          item = { ...item, filterOptions: { ...item.filterOptions, filterDropdownItems: [...this.levels.map(el => ({ value: el.id, text: el.name }))] } }
        }
        if (item.label === 'Status') {
          // eslint-disable-next-line no-param-reassign
          item = { ...item, filterOptions: { ...item.filterOptions, filterDropdownItems: [...this.statuses.map(el => ({ value: el.id, text: el.name }))] } }
        }
        return item
      })
      this.setVisibleFields()
    },
    onColumnFilter(prop) {
      const filterParams = prop.columnFilters

      let search = ''
      let searchFields = ''

      Object.keys(filterParams).forEach(key => {
        if (filterParams[key]) {
          search += `${key}:${filterParams[key]};`
          // remake, only the created_at, activation_at, renewal_at, expiry_at should have :like
          if (key === 'created_at' || key === 'activation_at' || key === 'renewal_at' || key === 'expiry_at') {
            searchFields += `${key}:like;`
          } else {
            searchFields += `${key};`
          }
        }
      })
      this.search = search
      this.searchFields = searchFields
      this.getSiteLicence()
    },
    handleReset() {
      this.resetFilter()
      this.searchValue = ''
    },
    async handleMobileSearch(search, searchValue) {
      if (this.search !== searchValue) {
        this.searchValue = searchValue
      }

      this.columnFilterFn(search, true)
    },
    closeModal() {
      this.$refs['licence-info'].hide()
    },
    onColumnFilterAdd(prop) {
      const filterParams = prop.columnFilters

      let search = ''
      let searchFields = ''

      Object.keys(filterParams).forEach(key => {
        if (filterParams[key]) {
          search += `${key}:${filterParams[key]};`
          // remake, only the created_at, activation_at, renewal_at, expiry_at should have :like
          if (key === 'created_at' || key === 'activation_at' || key === 'renewal_at' || key === 'expiry_at') {
            searchFields += `${key}:like;`
          } else {
            searchFields += `${key};`
          }
        }
      })
      this.searchAdd = search
      this.searchFieldsAdd = searchFields

      this.getAvailableSiteLicence()
    },
    closeAddLicenceModal() {
      this.$refs['add-licence-modal'].hide()
    },
    onSortListChange(params) {
      this.orderBy = params[0].field
      this.sortedBy = params[0].type
      this.getSiteLicence()
    },
    onSortListChangeAdd(params) {
      this.orderByAdd = params[0].field
      this.sortedByAdd = params[0].type
      this.getAvailableSiteLicence()
    },
    async unlocateHardware() {
      try {
        await axios.delete(`/site/${this.$route.params.id}/licences`, {
          params: {
            licenceIds: [this.idToUnlocate],
          },
        })

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'The licence has been removed from the site',
            icon: 'BellIcon',
            variant: 'success',
          },
        })

        this.idToUnlocate = null

        if (this.fetchControllerLicence) {
          this.fetchControllerLicence()
        }

        this.getSiteLicence()
      } catch (error) {
        const { status } = error.response

        if (status === 422) {
          let detailContent = ''

          if (error?.response?.data?.fields) {
            detailContent += 'You cannot remove this item because the entity has the following relation(s):<br>\n'
            Object.keys(error.response.data.fields).forEach(field => {
              detailContent += ` - ${field}: \n 
                <ul class="mb-1">
                  ${error.response.data.fields[field].map(item => `<li>${item}</li>`).join('')}
                  </ul>`
            })
          }

          this.showModal({
            title: 'Validation error',
            content: detailContent,
            icon: 'BellIcon',
            variant: 'danger',
          })

          this.deleteErrorPopUpInfo = {
            title: 'Validation error',
            content: detailContent,
            icon: 'BellIcon',
            variant: 'danger',
          }
        }
      }
    },
    async getSiteLicence() {
      const sortOptionURL = this.orderBy && this.sortedBy ? `&orderBy=${this.orderBy}&sortedBy=${this.sortedBy}` : ''
      const searchURL = this.search ? `&search=${this.search}&searchFields=${this.searchFields}` : ''
      const { data } = await axios.get(`/site/${this.$route.params.id}/licences/list?page=${this.listParams.page}&perPage=${this.listParams.perPage}&searchJoin=${this.listParams.searchJoin}${sortOptionURL}${searchURL}`)
      this.licenceItems = data.data
      this.categories = data.options.categories.data
      this.types = data.options.types.data
      this.levels = data.options.levels.data
      this.statuses = data.options.statuses.data

      this.isLoading = false
      this.pagination = data.meta.pagination
    },
    async getAvailableSiteLicence() {
      this.isLoadingAddLicence = true
      const sortOptionURL = this.orderByAdd && this.sortedByAdd ? `&orderBy=${this.orderByAdd}&sortedBy=${this.sortedByAdd}` : ''
      const searchURL = this.searchAdd ? `&search=${this.searchAdd}&searchFields=${this.searchFieldsAdd}` : ''
      const { data } = await axios.get(`/site/licences/free?page=${this.listParamsAdd.page}&perPage=${this.listParamsAdd.perPage}&searchJoin=${this.listParamsAdd.searchJoin}${sortOptionURL}${searchURL}`)
      this.addLicenceItems = data.data
      this.isLoadingAddLicence = false
      this.paginationAdd = data.meta.pagination
    },
    async addLicenceToTheSite(id) {
      try {
        await axios.post(`/site/${this.$route.params.id}/licences`, {
          licenceIds: [id],
        })
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'The licence has been added to the site',
            icon: 'BellIcon',
            variant: 'success',
          },
        })
        this.getSiteLicence()
        this.getAvailableSiteLicence()

        if (this.fetchControllerLicence) {
          this.fetchControllerLicence()
        }
      } catch (error) {
        console.warn('Error while adding licence to the site', error)
      }
    },
    async onSelectHardwareInfo(id) {
      const getStatusColor = statusId => {
        let colorVariant
        switch (statusId) {
          case 'un-allocated':
            colorVariant = 'info'
            break
          case 'allocated':
            colorVariant = 'warning'
            break
          case 'activated':
            colorVariant = 'success'
            break
          case 'renewed':
            colorVariant = 'primary'
            break
          case 'ceasing':
            colorVariant = 'secondary'
            break
          case 'expired':
            colorVariant = 'danger'
            break
          default:
            colorVariant = 'danger'
        }
        return colorVariant
      }
      try {
        const { data } = await axios.get(`/licence/${id}/show`)
        this.selectedHardwareModalInfo = { ...data.data, id, statusColor: getStatusColor(data.data.status.id) }
        this.$refs['licence-info'].show()
      } catch (error) {
        console.warn('Error while fetching licence info', error)
      }
    },
    ...mapMutations('licence', ['RESET_STATE']),
    dateRangeFilter(data, filterString) {
      const dateRange = filterString.split(',')
      const startDate = Date.parse(dateRange[0])
      const endDate = Date.parse(dateRange[1])
      // eslint-disable-next-line no-return-assign, no-param-reassign
      return (data = Date.parse(data) >= startDate && Date.parse(data) <= endDate)
    },
    setVisibleFields() {
      this.fields = this.fields.map(item => {
        if (item.field === 'masterDistributor.name' && !this.permissions.includes('masterDistributor')) {
          // eslint-disable-next-line no-param-reassign
          item = { ...item, hidden: true }
        }
        if (item.field === 'distributor.name' && !this.permissions.includes('distributor')) {
          // eslint-disable-next-line no-param-reassign
          item = { ...item, hidden: true }
        }
        if (item.field === 'reseller.name' && !this.permissions.includes('reseller')) {
          // eslint-disable-next-line no-param-reassign
          item = { ...item, hidden: true }
        }
        if (item.field === 'client.name' && !this.permissions.includes('client')) {
          // eslint-disable-next-line no-param-reassign
          item = { ...item, hidden: true }
        }
        return item
      })
    },
    async changeStatus(item) {
      this.enableActionInProgress = true
      try {
        await this.updateLicenceStatus({ ids: [item.id], is_enable: item.is_enable })

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success!',
            icon: 'BellIcon',
            variant: 'success',
          },
        })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Server error',
            icon: 'BellIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.enableActionInProgress = false
      }
    },
    setStatusColor(statusName) {
      let colorVariant
      switch (statusName) {
        case 'un-allocated':
          colorVariant = 'info'
          break
        case 'allocated':
          colorVariant = 'warning'
          break
        case 'activated':
          colorVariant = 'success'
          break
        case 'renewed':
          colorVariant = 'primary'
          break
        case 'ceasing':
          colorVariant = 'secondary'
          break
        case 'expired':
          colorVariant = 'danger'
          break
        default:
          colorVariant = 'danger'
      }
      return colorVariant
    },
    rowStyleClassFn(row) {
      return row.is_deleted ? 'deleted-class' : ''
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  .vgt-table{
    .vgt-left-align, .vgt-right-align{
      vertical-align: middle !important;
    }
  }
  .dark-layout{
    .vgt-select{
      background-color: #283046;
      border-color: #404656;
    }
    .table-selected-row-info-panel{
      background-color: #283046;
      border-color: #404656;
    }
  }
  .licence-table {
    .deleted-class {
      .vgt-checkbox-col {
        background-color: rgba(255, 0, 0, 0.185) !important;
        input {
          display: none;
        }
      }
      .vgt-left-align, .vgt-right-align {
        background-color: rgba(255, 0, 0, 0.185) !important;
      }
    }
  }
</style>
