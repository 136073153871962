<template>
  <b-card
    no-body
    class="table-border-container"
  >
    <b-row
      class="mt-2 mb-2"
    >
      <b-col>
        <div class="site-hardware-actions site-hardware-actions_management wifi-setting">
          <div class="wifi-setting-label">
            <p class="label">
              Registrations
            </p>
          </div>
          <div
            class="double-button actions-wifi-settings"
          >
            <b-dropdown
              ref="dropdown"
              toggle-class="more-dropdown-transparent"
              dropdown
              right
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="FilterIcon"
                  size="16"
                  class="text-body align-middle"
                  color="#F0554E"
                />
              </template>

              <!-- list of checkboxes to filter columns -->
              <b-dropdown-item
                v-for="(label, field) in fieldLabelsSelected"
                :key="field"
                @click="changeColumns(field)"
              >
                <b-form-checkbox
                  v-model="fieldLabelsSelected[field].selected"
                  @change="changeColumns(field)"
                >
                  {{ label.label }}
                </b-form-checkbox>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </b-col>
    </b-row>

    <b-tabs
      v-model="activeTab"
      card
      pills
      class="site-info-tabs no-margins"
      :class="{'one-tab': newCapsman}"
    >
      <b-tab
        v-if="!newCapsman"
        title="Wi-Fi 5"
        active
      />
      <b-tab
        title="Wi-Fi 6+"
      />
    </b-tabs>
    <table-mobile-solo
      :handle-search="null"
      :loading="isLoading"
      :items="items"
      :custom-class="'no-padding'"
      :fields="fields.filter(field => field.field !== 'is_enabled')"
      key-value="accessPoint"
      :actions="[{
        label: 'Delete',
        type: 'primary',
        icon: 'TrashIcon',
        visible: () => true,
        click: (item) => handleDelete(item.id),
      }]"
    />
    <b-card-body>
      <vue-good-table
        ref="VueGoodTable"
        :is-loading="isLoading"
        :columns="fields"
        :rows="items"
        class="hide-mobile-table"
        mode="remote"
        compact-mode
        :sort-options="{
          enabled: true,
          multipleColumns: true,
          initialSortBy: {field: orderBy, type: sortedBy}
        }"
        @on-sort-change="onSortChange"
      >
        <template slot="loadingContent">
          <b-spinner
            variant="primary"
            label="Spinning"
          />
        </template>
        <template
          slot="table-row"
          slot-scope="props"
        >
          <span v-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item
                  @click.prevent="handleDelete(props.row.id)"
                >
                  <feather-icon
                    icon="TrashIcon"
                    class="mr-50"
                  />
                  <span>Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>
          <div
            v-else-if="props.column.field === 'mac_address'"
            v-b-modal.access-control-modal
            @click="openBlockDeviceModal(props.formattedRow[props.column.field])"
          >
            <span>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </div>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
    </b-card-body>
    <AddToBlockListModal
      :site-id="siteId"
      :selected-item="selectedItem"
      :is-edit-form="false"
      @close-modal="resetModal"
    />
    <b-modal
      id="modalDelete"
      ref="modalDelete"
      title="Are you sure you want to permanently delete this record?"
      centered
      ok-title="Delete"
      @ok="deleteModalHandler"
    >
      <div>
        Permanently deleted records cannot be recovered later.
      </div>
    </b-modal>
  </b-card>
</template>

<script>

import { mapActions } from 'vuex'

import {
  BCard, BCardBody, BDropdown, BDropdownItem, BSpinner, BRow, BCol, BFormCheckbox, BTabs, BTab,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required, max } from '@validations'

import { VueGoodTable } from 'vue-good-table'
import TableMobileSolo from '@/components/TableMobileSolo.vue'
import axios from '../../axios-resolver'
import AddToBlockListModal from './access-control/CreateEditModal.vue'

export default {
  components: {
    BCardBody,
    VueGoodTable,
    AddToBlockListModal,
    BDropdown,
    BDropdownItem,
    BCard,
    BSpinner,
    BRow,
    BFormCheckbox,
    BCol,
    BTabs,
    BTab,
    TableMobileSolo,
  },
  props: {
    siteId: {
      required: true,
      type: String,
    },
    newCapsman: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      isLoading: true,
      selectedItem: null,
      items: [],

      activeTab: null,
      searchValue: '',

      pagination: {
        total: 0,
        per_page: 10,
        last_page: 0,
        current_page: 1,
      },
      page: 1,
      orderBy: null,
      sortedBy: null,
      perPage: 25,
      pageSizeOptions: [25, 50, 75, 100],
      recordToDelete: null,
      fields: [
        {
          field: 'accessPoint',
          label: 'Access Point',
        },
        {
          field: 'radio_band',
          label: 'Radio Band',
          sortable: false,
        },
        {
          field: 'ssid',
          label: 'SSID',
          sortable: false,
        },
        {
          field: 'mac_address',
          label: 'MAC Address',
          sortable: false,
        },
        {
          field: 'host_name',
          label: 'Host Name',
          sortable: false,
        },
        {
          field: 'tx_rate',
          label: 'Tx Rate',
          sortable: false,
        },
        {
          field: 'rx_rate',
          label: 'Rx Rate',
          sortable: false,
        },
        {
          field: 'rx_signal',
          label: 'Rx Signal ',
          sortable: false,
        },
        {
          field: 'bytes',
          label: 'Tx/Rx Bytes',
          sortable: false,
        },
        {
          field: 'uptime',
          label: 'Uptime',
          sortable: false,
        },
        {
          field: 'action',
          label: 'Actions',
          sortable: false,
          thClass: 'action-th-width',
        },
      ],
      fieldLabelsSelected: {
        accessPoint: { label: 'Access Point', selected: true },
        radio_band: {
          label: 'Radio Band',
          selected: true,
          sortable: false,
        },
        ssid: { label: 'SSID', selected: true },
        mac_address: {
          label: 'MAC Address',
          selected: true,
          sortable: false,
        },
        host_name: { label: 'Host Name', selected: true },
        tx_rate: {
          label: 'Tx Rate',
          selected: true,
          sortable: false,
        },
        rx_rate: {
          label: 'Rx Rate',
          selected: true,
          sortable: false,
        },
        rx_signal: {
          label: 'Rx Signal',
          selected: true,
          sortable: false,
        },
        bytes: {
          label: 'Tx/Rx Bytes',
          selected: true,
          sortable: false,
        },
        uptime: { label: 'Uptime', selected: true },
      },
      required,
      max,

      isNewCapsMan: false,
    }
  },
  watch: {
    newCapsman(isNewCapsmanOnly) {
      this.activeTab = !isNewCapsmanOnly ? 'Wi-Fi 5' : 'Wi-Fi 6+'
    },
    activeTab(newValue) {
      this.isNewCapsMan = newValue
      this.isLoading = true
      this.resetTable()
      this.fetchTable()
    },
  },
  methods: {
    resetTable() {
      this.orderBy = null
      this.sortedBy = null
    },
    ...mapActions('registeredDevices', [
      'updateItem',
      'deleteItem',
    ]),
    async fetchTable() {
      try {
        const sortOptionURL = this.orderBy && this.sortedBy ? `?orderBy=${this.orderBy}&sortedBy=${this.sortedBy}` : ''
        const { data } = await axios.get(`/site/${this.siteId}/wifi/${this.isNewCapsMan ? 'new-' : ''}registration/index${sortOptionURL}`)
        this.items = data.data
        this.isLoading = false
      } catch (error) {
        console.warn(error)
      }
    },
    onSortChange(params) {
      clearTimeout(this.sortTimeout)

      setTimeout(() => {
        this.orderBy = params[0].field
        this.sortedBy = params[0].type
        if (params[0].type) {
          this.fetchTable()
        }
      }, 200)
    },
    openBlockDeviceModal(macAddress) {
      this.selectedItem = {
        mac_address: macAddress,
      }
    },
    resetModal() {
      this.selectedItem = null
    },
    handleReset() {
      this.resetFilter()
      this.searchValue = ''
    },
    async fetchItems() {
      try {
        await this.getItems(this.siteId)
      } catch (error) {
        const { status } = error.response
        if (status === 404) {
          this.$router.push({ name: 'error-404' })
        }
        if (!status || status === 500) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Server error',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        } else {
          const { message } = error.response.data

          this.$toast({
            component: ToastificationContent,
            props: {
              title: message,
              icon: 'BellIcon',
              variant: 'danger',
              message,
            },
          })
        }
      } finally {
        this.isLoading = false
      }
    },
    changeColumns(field) {
      this.fields = this.fields.map(item => {
        if (item.field === field) {
          return {
            ...item,
            hidden: !item.hidden,
          }
        }
        return item
      })

      this.fieldLabelsSelected = {
        ...this.fieldLabelsSelected,
        [field]: {
          ...this.fieldLabelsSelected[field],
          selected: !this.fieldLabelsSelected[field].selected,
        },
      }
      this.$refs.dropdown.show()
    },
    handleDelete(id) {
      this.recordToDelete = id
      this.$refs.modalDelete.show()
    },
    async deleteModalHandler() {
      try {
        await this.deleteItem({ siteId: this.siteId, ids: [this.recordToDelete] })
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Deleted!',
            icon: 'BellIcon',
            variant: 'success',
          },
        })
      } catch (error) {
        const { status } = error.response

        if (!status) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Server error',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        } else {
          const { message } = error.response.data

          this.$toast({
            component: ToastificationContent,
            props: {
              title: message,
              icon: 'BellIcon',
              variant: 'danger',
              message,
            },
          })
        }
      }
    },
  },
}
</script>

<style lang="scss">
</style>
