<template>
  <b-card
    class="no-padding"
    no-body
  >
    <div class="section-block m-2">
      <div class="site-hardware-actions site-hardware-actions_management mb-2">

        <div class="section-block__title mb-0">
          <h2>Site hardware</h2>
        </div>
        <div class="double-button">
          <b-button
            v-if="$can('create', 'hardware')"
            v-b-modal.modal-add-hardware
            variant="primary"
          >
            Add hardwares to site
          </b-button>
        </div>
      </div>
      <AddHardwareModal
        :added-items="items"
        :update="fetchItems"
      />
      <b-row
        v-if="!Object.keys(filterSelectedOptions).length > 0 ? false : true"
        class="p-2"
      >
        <b-col
          md="8"
          order-md="1"
          order="2"
        >
          <b-form @submit.prevent="searchRows()">
            <b-form-row class="align-items-end">
              <b-col>
                <feather-icon
                  v-if="(searchFields && searchFields.length > 0) || (orderBy && orderBy.length > 0) || (sortedBy && sortedBy.length > 0)"
                  v-b-tooltip.hover
                  icon="XIcon"
                  title="Reset filters"
                  size="24"
                  class="text-body align-middle mr-25"
                  @click="handleReset"
                />
              </b-col>
            </b-form-row>
          </b-form>
        </b-col>
      </b-row>
      <table-mobile-solo
        :search="searchValue"
        :handle-search="handleMobileSearch"
        :mobile-search-fields="mobileSearchFields"
        :loading="isLoading"
        :items="items"
        :fields="fields"
        :pagination="{
          pagination: pagination,
          perPage: perPage,
          currentPage: page,
          pageSizeOptions: pageSizeOptions,
          enabled: false,
          perPageChanged: perPageChanged,
          changePage: changePage,
        }"
        :rules="{
          model(val) {
            return val.name
          },
          licence(val) {
            return val.name
          },
        }"
        :actions="[{
          label: 'Edit',
          type: 'primary',
          icon: 'Edit2Icon',
          visible: () => this.$can('update', 'hardware'),
          click: (item) => toEdit(item.id),
        }, {
          label: 'Remove',
          type: 'danger',
          icon: 'TrashIcon',
          visible: () => true,
          click: (item) => deassociateItem(item.id),
        }, {
          label: 'Download config file',
          type: 'primary',
          icon: 'DownloadIcon',
          visible: (item) => item.model.config_file,
          click: (item) => openNewWindow(item.model.config_file),
        }, {
          label: 'Download build file',
          type: 'primary',
          icon: 'DownloadIcon',
          visible: (item) => item.model.dpk_file,
          click: (item) => openNewWindow(item.model.dpk_file),
        }]"
      />
      <b-card-body>
        <vue-good-table
          ref="VueGoodTable"
          :is-loading="isLoading"
          compact-mode
          :columns="fields"
          :rows="items"
          :total-rows="pagination.total"
          mode="remote"
          :sort-options="{
            enabled: true,
            multipleColumns: true,
            initialSortBy: {field: orderBy, type: sortedBy}
          }"
          :pagination-options="{
            enabled: true,
            perPage: perPage,
            setCurrentPage: page
          }"
          class="hardware-table-status hide-mobile-table"
          @on-sort-change="onSortChange"
          @on-column-filter="columnFilterFn"
        >
          <template slot="loadingContent">
            <b-spinner
              variant="primary"
              label="Spinning"
            />
          </template>
          <template
            slot="table-row"
            slot-scope="props"
          >
            <span v-if="props.column.field === 'action'">
              <span>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item
                    v-if="$can('update', 'hardware')"
                    @click.prevent="toEdit(props.row.id)"
                  >
                    <feather-icon
                      icon="Edit2Icon"
                      class="mr-50"
                    />
                    <span>Edit</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click.prevent="deassociateItem(props.row.id)"
                  >
                    <feather-icon
                      icon="TrashIcon"
                      class="mr-50"
                    />
                    <span>Remove</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="props.row.model.config_file"
                    :href="props.row.model.config_file"
                    exact-path
                    target="_blank"
                  >
                    <feather-icon
                      icon="DownloadIcon"
                      class="mr-50"
                    />
                    <span>Download config file</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="props.row.model.dpk_file"
                    :href="props.row.model.dpk_file"
                    exact-path
                    target="_blank"
                  >
                    <feather-icon
                      icon="DownloadIcon"
                      class="mr-50"
                    />
                    <span>Download build file</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </span>
            <span v-else-if="props.column.field === 'name'">
              <button
                v-if="props.row.model.manufacturer.name === 'ZyXEL'"
                class="manufacture-disable-stats"
                @click="handleZyXELInfo"
              >
                {{ props.formattedRow[props.column.field] }}
              </button>
              <router-link
                v-else-if="['controller-combined', 'odu'].includes(props.row.model.type.id)"
                :to="`/site/${id}/hardware/lte/${props.row.id}`"
              >
                {{ props.formattedRow[props.column.field] }}
              </router-link>
              <router-link
                v-else-if="props.row.model.type.id === 'switch'"
                :to="`/site/${id}/hardware/ports/${props.row.id}`"
              >
                {{ props.formattedRow[props.column.field] }}
              </router-link>
              <span v-else>{{ props.formattedRow[props.column.field] }}</span>
            </span>
            <span
              v-else-if="props.column.field === 'licence.name'"
            >
              <router-link
                v-if="props.formattedRow['licence']"
                :to="`/licence/update/${props.formattedRow['licence'].id}`"
              >
                {{ props.formattedRow[props.column.field] }}
              </router-link>
            </span>
            <span v-else-if="props.column.field === 'is_online'">
              <b-badge
                v-if="props.row.model.manufacturer.name === 'ZyXEL'"
                pill
                variant="secondary"
                class="clickable"
                @click="handleZyXELInfo"
              >N/A</b-badge>
              <b-badge
                v-else-if="props.formattedRow[props.column.field] === '-' || !props.formattedRow[props.column.field]"
                pill
                variant="danger"
              >Offline</b-badge>
              <b-badge
                v-else
                pill
                variant="success"
              >Online</b-badge>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
          <!-- pagination -->
          <div
            slot="pagination-bottom"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap">
                  Showing 1 to
                </span>
                <b-form-select
                  v-model="perPage"
                  :options="pageSizeOptions"
                  class="mx-1"
                  @change="perPageChanged"
                />
                <span class="text-nowrap"> of {{ pagination.total }} entries </span>
              </div>
              <div>
                <b-pagination
                  v-if="pagination.last_page !== 1"
                  :value="page"
                  :total-rows="pagination.total"
                  :per-page="perPage"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @change="changePage"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </div>
        </vue-good-table>
      </b-card-body>
    </div>
    <b-modal
      id="modalDeleteLoader"
      ref="modalDeleteLoader"
      :title="`Disassociating in progress...`"
      hide-footer
      centered
      hide-close
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      size="sm"
    >
      <div class="d-flex justify-content-center align-items-center mt-2 mb-2">

        <b-spinner
          variant="primary"
          label="Spinning"
          style="width: 3rem; height: 3rem;"
          huge
        />
      </div>
    </b-modal>

    <b-modal
      id="zyxelInfo"
      ref="zyxelInfo"
      title="Notice for ZyXEL Clients"
      centered
      ok-only
      ok-variant="secondary"
      ok-title="Cancel"
    >
      <div class="mt-2 mb-2">

        <p>Remote access is not supported for ZyXEL ODUs. These devices can only be accessed onsite or through a support ticket.</p>
        <p>If you require assistance, please contact your supplier, and we will be happy to help.</p>
        <p>Thank you for your understanding.</p>
      </div>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCardBody, BCard, BPagination, BRow, BCol, BDropdown, BDropdownItem, BForm, BFormRow, BButton, BFormSelect, BSpinner, BBadge, VBTooltip,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import IndexPagesMixin from '@/mixins/IndexPages.vue'
import { mapActions, mapState } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'
import TableMobileSolo from '@/components/TableMobileSolo.vue'
import AddHardwareModal from './AddHardwareModal.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BCardBody, BCard, BPagination, VueGoodTable, BDropdown, BDropdownItem, BRow, BCol, BForm, BFormRow, BButton, BFormSelect, BSpinner, BBadge, AddHardwareModal, TableMobileSolo,
  },
  mixins: [
    IndexPagesMixin,
  ],
  data() {
    return {
      id: this.$route.params.id,
      uppdateRouteName: 'hardware-update',
      oldItems: [],
      searchValue: '',
      mobileSearchFields: {
        name: '',
        'model.name': '',
        serial_number: '',
        comment: '',
      },
      fields: [
        {
          field: 'name',
          label: 'Name',
          filterOptions: {
            enabled: true,
            placeholder: 'Filter',
            filterValue: '',
          },
        },
        {
          field: 'model.name',
          mobileSpecial: 'model',
          label: 'Model',
          filterOptions: {
            enabled: true,
            placeholder: 'Filter',
            filterValue: '',
          },
        },
        {
          field: 'serial_number',
          label: 'Serial No.',
          filterOptions: {
            enabled: true,
            placeholder: 'Filter',
            filterValue: '',
          },
        },
        {
          field: 'current_system_version',
          label: 'Version',
          filterOptions: {
            enabled: false,
            placeholder: 'Filter',
            filterValue: '',
          },
        },
        {
          field: 'is_online',
          label: 'Online Status',
          filterOptions: {
            enabled: true,
            placeholder: 'Status',
            filterValue: '',
          },
        },
        {
          field: 'licence',
          hidden: true,
        },
        {
          field: 'licence.name',
          label: 'Licence №',
          mobileSpecial: 'licence',
          filterOptions: {
            enabled: false,
            placeholder: 'Filter',
            filterValue: '',
          },
        },
        {
          field: 'comment',
          label: 'Comment',
          filterOptions: {
            enabled: true,
            placeholder: 'Filter',
            filterValue: '',
          },
        },
        {
          field: 'ip_address',
          label: 'IP Address',
          filterOptions: {
            enabled: true,
            placeholder: 'Filter',
            filterValue: '',
          },
        },
        {
          field: 'action',
          label: 'Action',
          sortable: false,
          thClass: 'action-th-width',
          hidden: !this.$can('update', 'hardware') && !this.$can('delete', 'hardware'),
        },
      ],
      isLoading: true,
      statusTimeout: null,
    }
  },
  computed: {
    ...mapState('status', {
      items: 'items',
      pagination: 'pagination',
      searchFields: 'searchFields',
      orderBy: 'orderBy',
      sortedBy: 'sortedBy',
    }),
  },
  watch: {
    items() {
      clearTimeout(this.statusTimeout)
      if (this.items.length > 0) {
        const ids = this.items.map(item => item.id)

        const checkStatus = () => {
          if (router.currentRoute.params.id) {
            this.statusTimeout = setTimeout(async () => {
              await this.handleRunCheckStatus(ids)
              this.doesOnlineStatusChange()
              checkStatus()
            }, 15_000)
          } else {
            clearTimeout(this.statusTimeout)
          }
        }

        checkStatus()
      }
    },
  },
  destroyed() {
    clearTimeout(this.statusTimeout)
  },
  methods: {
    ...mapActions('sites', [
      'getSiteSummary',
    ]),
    ...mapActions('status', {
      fetchItems: 'getItems',
      setSearchFields: 'setSearchFields',
      setOrderBy: 'setOrderBy',
      setSortedBy: 'setSortedBy',
      handleDeassociateItem: 'deassociateItem',
      handleRunCheckStatus: 'runCheckStatus',
    }),
    ...mapActions('hardware', {
      deassociateItems: 'deassociateItems',
    }),
    doesOnlineStatusChange() {
      if (this.oldItems.length > 0) {
        this.items.forEach(item => {
          const oldItem = this.oldItems.find(oldItemProp => oldItemProp.id === item.id)

          if (oldItem && oldItem.is_online !== item.is_online) {
            this.getSiteSummary(this.id)
          }
        })
      }
      this.oldItems = this.items
    },
    handleZyXELInfo() {
      this.$refs.zyxelInfo.show()
    },
    openNewWindow(url) {
      window.open(url, '_blank')
    },
    handleReset() {
      this.resetFilter()
      this.searchValue = ''
    },
    async handleMobileSearch(search, searchValue) {
      if (this.search !== searchValue) {
        this.searchValue = searchValue
      }

      this.columnFilterFn(search, true)
    },
    async deassociateItem(id) {
      try {
        this.$refs.modalDeleteLoader.show()
        await this.handleDeassociateItem(id)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success!',
            icon: 'BellIcon',
            variant: 'success',
          },
        })
        await this.fetchItems()
      } catch (error) {
        const { status } = error.response
        if (!status) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Server error',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.response.data.message,
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        }
      } finally {
        this.$refs.modalDeleteLoader.hide()
      }
    },
  },
}
</script>

<style lang="scss">
.hardware-table-status {
  overflow-x: auto;
}
.no-padding {
  .card-body{
    padding: 0;
  }
}
</style>
