<template>
  <b-card
    no-body
    class="table-border-container"
  >
    <b-row
      class="mt-2 mb-2"
    >
      <b-col>
        <div class="site-hardware-actions site-hardware-actions_management wifi-setting">
          <div class="wifi-setting-label">
            <p class="label">
              Access points
            </p>
          </div>
          <div
            class="double-button actions-wifi-settings"
          >
            <b-dropdown
              ref="dropdown"
              toggle-class="more-dropdown-transparent"
              dropdown
              right
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="FilterIcon"
                  size="16"
                  class="text-body align-middle"
                  color="#F0554E"
                />
              </template>

              <!-- list of checkboxes to filter columns -->
              <b-dropdown-item
                v-for="(label, field) in fieldLabelsSelected"
                :key="field"
                :disabled="label.label === 'Status' && !!activeTab"
                @click="changeColumns(field)"
              >
                <b-form-checkbox
                  v-model="fieldLabelsSelected[field].selected"
                  :disabled="label.label === 'Status' && !!activeTab"
                  @change="changeColumns(field)"
                >
                  {{ label.label }}
                </b-form-checkbox>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </b-col>
    </b-row>

    <b-tabs
      v-model="activeTab"
      card
      pills
      class="site-info-tabs no-margins"
      :class="{'one-tab': newCapsman}"
    >
      <b-tab
        v-if="!newCapsman"
        title="Wi-Fi 5"
        active
      />
      <b-tab
        title="Wi-Fi 6+"
      />
    </b-tabs>
    <table-mobile-solo
      :handle-search="null"
      :items="items"
      :custom-class="'no-padding'"
      :loading="isLoading"
      :fields="fields.filter(field => field.field !== 'is_enabled')"
      :actions="[{
        label: 'Edit',
        type: 'primary',
        icon: 'EditIcon',
        visible: () => true,
        click: (item) => {
          openEditModal(item, true);
        },
      }, {
        label: (item) => item.enabled ? 'Disable' : 'Enable',
        type: 'primary',
        icon: 'EditIcon',
        visible: () => true,
        click: (item) => changeStatus(item),
      }]"
    />
    <b-card-body>
      <vue-good-table
        ref="VueGoodTable"
        :is-loading="isLoading"
        :columns="fields"
        :rows="items"
        mode="remote"
        compact-mode
        :sort-options="{
          enabled: true,
          multipleColumns: true,
          initialSortBy: {field: orderBy, type: sortedBy}
        }"
        class="hide-mobile-table"
        @on-sort-change="onSortChange"
      >
        <template slot="loadingContent">
          <b-spinner
            variant="primary"
            label="Spinning"
          />
        </template>
        <template
          slot="table-row"
          slot-scope="props"
        >
          <span v-if="props.column.field === 'actions'">
            <span class="d-flex justify-content-center">
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item
                  v-b-modal.access-point-modal
                  @click.prevent="openEditModal(props.row)"
                >
                  <feather-icon
                    icon="Edit2Icon"
                    class="mr-50"
                  />
                  <span>Edit</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>
          <span
            v-else-if="props.column.field === 'enabled'"
          >
            <b-form-checkbox
              v-model="props.formattedRow.enabled"
              name="is_enabled"
              switch
              inline
              @change="changeStatus(props.row)"
            />
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

      </vue-good-table>
    </b-card-body>

    <EditModal
      :site-id="siteId"
      :selected-item="selectedItem"
      :is-new-caps-man="isNewCapsMan"
      @close-modal="resetModal"
    />
  </b-card>
</template>

<script>

import {
  BCard, BCardBody, BSpinner, BDropdown, BDropdownItem, BFormCheckbox, BCol, BRow, BTabs, BTab,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import IndexPagesMixin from '@/mixins/IndexPages.vue'

import { VueGoodTable } from 'vue-good-table'
import TableMobileSolo from '@/components/TableMobileSolo.vue'
import axios from '../../../axios-resolver'
import EditModal from './EditModal.vue'

export default {
  components: {
    BCardBody,
    VueGoodTable,
    BCard,
    BSpinner,
    BDropdown,
    BCol,
    BRow,
    BDropdownItem,
    BFormCheckbox,
    EditModal,
    BTabs,
    BTab,
    TableMobileSolo,
  },
  mixins: [
    IndexPagesMixin,
  ],
  props: {
    siteId: {
      required: true,
      type: String,
    },
    newCapsman: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      isLoading: true,
      isItemSelected: false,
      selectedItem: null,
      items: [],

      activeTab: 'Wi-Fi 5',

      pagination: {
        total: 0,
        per_page: 10,
        last_page: 0,
        current_page: 1,
      },
      orderBy: null,
      sortedBy: null,
      fieldLabelsSelected: {
        name: {
          label: 'Interface Name',
          selected: true,
        },
        comment: {
          label: 'Comment',
          selected: true,
        },
        model: {
          label: 'Model',
          selected: true,
        },
        serial: {
          label: 'Serial',
          selected: true,
        },
        ssid: {
          label: 'SSID',
          selected: true,
        },
        network: {
          label: 'Network',
          selected: true,
        },
        channel: {
          label: 'Channel Selection',
          selected: true,
        },
        current_channel: {
          label: 'Current Channel',
          selected: true,
        },
        master: {
          label: 'Master Interface',
          selected: true,
        },
        current_state: {
          label: 'Status',
          selected: true,
        },
        enabled: {
          label: 'Enabled',
          selected: true,
        },
      },
      sortTimeout: null,
      pageSizeOptions: [25, 50, 75, 100],
      fields: [
        {
          field: 'id',
          sortable: false,
          hidden: true,
        },
        {
          field: 'name',
          label: 'Interface Name',
          sortable: false,
        },
        {
          field: 'comment',
          label: 'Comment',
          sortable: false,
        },
        {
          field: 'model',
          label: 'Model',
          sortable: false,
        },
        {
          field: 'serial',
          label: 'Serial',
          sortable: false,
        },
        {
          field: 'ssid',
          label: 'SSID',
          sortable: false,
        },
        {
          field: 'network',
          label: 'Network',
          sortable: false,
        },
        {
          field: 'channel',
          label: 'Channel Selection',
          sortable: false,
        },
        {
          field: 'channelList',
          sortable: false,
          hidden: true,
        },
        {
          field: 'current_channel',
          label: 'Current Channel',
          sortable: false,
        },
        {
          field: 'master',
          label: 'Master',
          sortable: false,
        },
        {
          field: 'master_interface',
          label: 'Master Interface',
          sortable: false,
        },
        {
          field: 'current_state',
          label: 'Status',
          sortable: false,
        },
        {
          field: 'enabled',
          label: 'Enabled',
          sortable: false,
        },
        {
          field: 'actions',
          label: 'Actions',
          sortable: false,
          thClass: 'action-th-width',

        },
      ],

      isNewCapsMan: false,
    }
  },
  watch: {
    newCapsman(isNewCapsmanOnly) {
      this.activeTab = !isNewCapsmanOnly ? 'Wi-Fi 5' : 'Wi-Fi 6+'
    },
    activeTab(newValue) {
      this.isNewCapsMan = newValue
      this.isLoading = true

      this.fields = this.fields.map(item => {
        if (item.field === 'current_state') {
          return {
            ...item,
            hidden: newValue,
          }
        }
        return item
      })

      this.fieldLabelsSelected = {
        ...this.fieldLabelsSelected,
        current_state: {
          ...this.fieldLabelsSelected.current_state,
          selected: !newValue,
        },
      }
      this.resetTable()
      this.fetchTable()
    },
  },
  methods: {
    resetTable() {
      this.orderBy = null
      this.sortedBy = null
    },
    async fetchTable() {
      try {
        const sortOptionURL = this.orderBy && this.sortedBy ? `?orderBy=${this.orderBy}&sortedBy=${this.sortedBy}` : ''
        const { data } = await axios.get(`/site/${this.siteId}/wifi/${this.isNewCapsMan ? 'new-' : ''}access-point/index${sortOptionURL}`)
        this.items = data.data.map(el => ({
          ...el,
          enabled: !el.disabled,
        }))
        this.isLoading = false
      } catch (error) {
        console.warn(error)
      }
    },
    onSortChange(params) {
      clearTimeout(this.sortTimeout)

      setTimeout(() => {
        this.orderBy = params[0].field
        this.sortedBy = params[0].type
        if (params[0].type) {
          this.fetchTable()
        }
      }, 200)
    },
    changeColumns(field) {
      this.fields = this.fields.map(item => {
        if (item.field === field) {
          return {
            ...item,
            hidden: !item.hidden,
          }
        }
        return item
      })

      this.fieldLabelsSelected = {
        ...this.fieldLabelsSelected,
        [field]: {
          ...this.fieldLabelsSelected[field],
          selected: !this.fieldLabelsSelected[field].selected,
        },
      }
      this.$refs.dropdown.show()
    },
    openEditModal(itemToEdit, fromMobile) {
      this.isItemSelected = true
      this.selectedItem = itemToEdit

      if (fromMobile) {
        this.$bvModal.show('access-point-modal')
      }
    },
    resetModal() {
      this.isItemSelected = false
      this.selectedItem = null
    },
    async changeStatus(item) {
      try {
        if (item.enabled) {
          await axios.put(`/site/${this.siteId}/wifi/${this.isNewCapsMan ? 'new-' : ''}access-point/disable`, {
            ids: [item.id],
          })
        } else {
          await axios.put(`/site/${this.siteId}/wifi/${this.isNewCapsMan ? 'new-' : ''}access-point/enable`, {
            ids: [item.id],
          })
        }
        this.fetchTable()

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success!',
            icon: 'BellIcon',
            variant: 'success',
          },
        })
      } catch (error) {
        console.warn('error', error)
      }
    },
  },
}
</script>

<style lang="scss">
</style>
